<template>
  <div class="container mt-20 mb-20 pb-20">
    <div>
      <router-link to="/">
        <img src="../assets/images/logo.png" width="200px"/>
      </router-link>
    </div>
    <el-card class="mt-20" shadow="hover" :body-style="{ padding: '20px' }">
      <div class="d-flex justify-content-center flex-column" slot="header">
        <h5>{{ $route.meta.title }}</h5>
      </div>
      <div class="content" v-if="detail" v-html="detail"></div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "List",

  data() {
    return {
      loading: false,

      detail: "",
    };
  },
  metaInfo() {
    return {
      title: this.$route.meta.title + "-" + this.Settings.base.name,
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    getDetail() {
      this.loading = true;
      this.$api.system.Settings.detail({ label: this.$route.meta.label })
        .then((res) => {
          this.loading = false;
          this.detail = res.data.content;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>